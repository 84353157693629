import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import DocumentListModal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Semblanza = ({ info, fontSize }) => {
    const [visible, setVisible] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const [facebook, setFacebook] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [instagram, setInstagram] = React.useState('');

    React.useEffect(() => {
        if(info.JAVASCRIPT !== undefined){
            let json = JSON.parse(info.JAVASCRIPT);
            console.log(json);
            setFacebook(json.facebook);
            setTwitter(json.twitter);
            setLinkedin(json.linkedin);
            setInstagram(json.instagram);
        }
        setLoading(false);
    }, [ info ]);

    // center navbar and content when is mobile device

    return (
        <>
            {(loading===false)?(
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={2} md={2} sm={8} xs={8} className="mt-4 mb-4">
                        <Image fluid src={info.URL} roundedCircle />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                        <br />
                        <h1>{info.NOMBRE}</h1>
                        {(facebook!=='')&&(
                            <>
                                <a href={facebook} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebook} size="2x" className="mr-2" />
                                </a>
                                &nbsp;&nbsp;
                            </>
                        )}
                        {(twitter!=='')&&(
                            <>
                                <a href={twitter} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitter} size="2x" className="mr-2" color="lightblue" />
                                </a>
                                &nbsp;&nbsp;
                            </>
                        )}
                        {(linkedin!=='')&&(
                            <>
                                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="mr-2" color="blue" />
                                </a>
                                &nbsp;&nbsp;
                            </>
                        )}
                        {(instagram!=='')&&(
                            <a href={instagram} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" className="mr-2" color="purple" />
                            </a>
                        )}
                        <h2>Actualizado: {info.FECHA}</h2>
                    </Col>
                </Row>
                <Row className='justify-content-md-center'>
                <Col md={8} className='mb-3 text-start'>
                    <button className='btn btn-secondary' onClick={() => setVisible(true)}>
                        <FontAwesomeIcon icon={faEnvelope} style={{fontSize: fontSize +'em'}} />&nbsp;
                        Solicitar más información
                    </button>
                </Col>
            </Row>
                <Row className="justify-content-md-center">
                    <Col lg={8} md={8} className="mb-4">
                        <h1>Descripci&oacute;n</h1>
                        <div dangerouslySetInnerHTML={{ __html: info.CONTENIDO }} className='content-text' />
                    </Col>
                </Row>
            </Container>
            ):(
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={8} md={8}>
                            <h1>Cargando...</h1>
                        </Col>
                    </Row>
                </Container>
            )}
            <DocumentListModal visible={visible} setVisible={setVisible} titulo={info.NOMBRE} />
        </>
    );
}

export { Semblanza };