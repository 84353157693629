import React from 'react';
import { Navbar, Container, Form, Button } from 'react-bootstrap';
import './NavBar.css';
import blackIcon from '../images/black.png';

const NavBar = ({ fontSize, setFontSize, isValid }) => {
    const moreFontSize = () => {
        setFontSize(fontSize + 0.1);
    }

    const lessFontSize = () => {
        setFontSize(fontSize - 0.1);
    }

    return (
        <>
            {(isValid === true) ? (
                <Navbar className='justify-content-between navegacion' expand="xs" sticky='top'>
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={blackIcon} alt="logo" height={30} width={30} />
                        </Navbar.Brand>

                        <Form>
                            <Button variant='secondary' style={{ fontSize: '0.8em' }} onClick={() => lessFontSize()}>
                                Aa
                            </Button>
                            &nbsp;
                            <Button variant='secondary' style={{ fontSize: '1.2em' }} onClick={() => moreFontSize()}>
                                Aa
                            </Button>
                        </Form>
                    </Container>
                </Navbar>
            ) : (
                null
            )}
        </>
    );
};

export { NavBar };